.grid:after {
  content: "";
  display: block;
}

.cell:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
 
.gridSiteTitle {
  font-size: 7vw;
  color: white;
}

.activeCell{
  background-color: white;
}

.activeCellLightOrange {
  background-color: #FF8C47;
}

.activeCellOrange {
  background-color: #E84941;
}

.activeCellPink {
  background-color: #FF54F8;
}

.activeCellPurple {
  background-color: #9245FF;
}

.titleApostrophe {
  color:  #FF9E03
}
