@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

h1#generationsText {
  font-size: 7vw;

  @media(min-width: 992px){
      font-size: 3vw;
  }

  @media(min-width: 1200px){
      font-size: 2vw;
  }
}

.controlPanel{
  .generationsNumber{
      color: #FF9E03
  } 

  .titleApostrophe {
      color:  #FF9E03
  }

  .adjustText {
      color: #FF9E03
  }

  #lgPrimaryControls {
      max-height: 186px;
  }
}

.App {
  background-color: #1C0D30;
  height: 100%;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
}

.appContainer {
  z-index: 1;
}

.containerContainer {
  max-width: 1600px;
  min-height: 100vh;
}

.sun {
  position: absolute;
  top: -40px;
  left: -40px;
  width: 130px;

  @media(min-width: 576px){
    top: -70px;
    left: -70px;
    width: 200px;
  }

  @media(min-width: 768px){
    top: -95px;
    left: -95px;
    width: 270px;
  }
 
  @media(min-width: 1800px){
    top: -95px;
    left: -95px;
    width: 300px;
  } 
}

@media (prefers-reduced-motion: no-preference) {
  .sun {
    animation: sun-rotate infinite 30s linear
  }
}

@keyframes sun-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


$theme-colors: (
    'primary': #FF8C47,
    'secondary': #E84941,
    'light': #FF54F8,
    'info': #9245FF
  );
  
@import '../node_modules/bootstrap/scss/bootstrap.scss'; 